import clsx from 'clsx';
import { isFunction } from 'lodash-es';
import { motion, useAnimation, PanInfo } from 'motion/react';
import React, { useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogProps,
  Heading,
  Modal,
  ModalOverlay,
  ModalOverlayProps,
  OverlayTriggerStateContext,
} from 'react-aria-components';
import { OverlayTriggerState } from 'react-stately';
import { twMerge } from 'tailwind-merge';
import { Text } from '../text/text';

const MotionModal = motion.create(Modal);
export function BottomSheet({
  children,
  className,
  isDismissable = true,
  ...props
}: Omit<ModalOverlayProps, 'children'> & Pick<DialogProps, 'children'>) {
  const draggableProps = useDraggableBottomSheet(isDismissable);

  return (
    <ModalOverlay
      className="rac-entering:animate-in rac-entering:fade-in rac-entering:duration-300 rac-exiting:animate-out rac-exiting:fade-out rac-exiting:duration-200 fixed inset-0 z-[999] flex h-[var(--visual-viewport-height)] w-screen flex-col items-center justify-end"
      isDismissable={isDismissable}
      {...props}
      style={{ backgroundColor: 'rgba(62, 58, 55, .4)' }}>
      <MotionModal
        className={(bag) =>
          twMerge(
            'rac-entering:animate-in shadow-8 rac-entering:slide-in-from-bottom rac-entering:duration-300 rac-exiting:animate-out rac-exiting:slide-out-to-bottom rac-exiting:duration-200 flex max-h-[95vh] min-h-[40vh] w-full flex-col rounded-t-xl bg-white',
            isFunction(className) ? className(bag) : className,
          )
        }
        {...draggableProps}>
        <Dialog className="relative flex min-h-0 flex-1 flex-col outline-none">
          {({ close }) => (
            <>
              {isDismissable && (
                <div className="bg-grey-600 mx-auto mt-2 h-1 w-12 rounded-lg opacity-40" />
              )}
              {isFunction(children) ? children({ close }) : children}
            </>
          )}
        </Dialog>
      </MotionModal>
    </ModalOverlay>
  );
}

function Header({
  title,
  subTitle,
  children,
  className,
}: {
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  className?: string;
}) {
  return (
    <header className={clsx('border-grey-300 border-b px-4 pb-3 pt-2 text-center', className)}>
      {title && (
        <Heading className="text-p3 line-clamp-2 font-medium" slot="title">
          {title}
        </Heading>
      )}
      {subTitle && (
        <Text className="mt-1.5 line-clamp-2" slot="description">
          {subTitle}
        </Text>
      )}
      {children}
    </header>
  );
}

function Body({ children }: { children?: React.ReactNode }) {
  return <div className="flex-1 overflow-y-auto p-2">{children}</div>;
}

function Footer({
  children,
}: {
  children?:
    | React.ReactNode
    | ((overlayTriggerStateContext: OverlayTriggerState) => React.ReactNode);
}) {
  const context = React.useContext(OverlayTriggerStateContext);

  return (
    <footer className="border-grey-300 flex items-center justify-end border-t bg-white p-4">
      {isFunction(children) ? children(context as OverlayTriggerState) : children}
    </footer>
  );
}

const useDraggableBottomSheet = (isDismissable: boolean) => {
  const controls = useAnimation();
  const { close } = React.useContext(OverlayTriggerStateContext) || {};

  const draggableProps = isDismissable
    ? {
        drag: 'y' as const,
        dragConstraints: { top: 0 },
        dragElastic: 0.2,
        initial: 'visible',
        onDragEnd: (
          event: globalThis.MouseEvent | globalThis.TouchEvent | PointerEvent,
          info: PanInfo,
        ) => handleDragEnd(event, info),
        animate: controls,
        transition: {
          type: 'spring',
          damping: 40,
          stiffness: 400,
        },
        variants: {
          visible: {
            y: 0,
          },
          closed: { y: '100%' },
        },
      }
    : {};

  const handleDragEnd = useCallback(
    (_: globalThis.MouseEvent | globalThis.TouchEvent | PointerEvent, info: PanInfo) => {
      if (isDismissable) {
        if (info.offset.y > 100) {
          controls.start('closed');
          close && close();
        } else {
          controls.start('visible');
        }
      }
    },
    [isDismissable, controls, close],
  );

  useEffect(() => {
    if (isDismissable) {
      //initial state
      controls.start('visible');
    }
  }, [isDismissable]);

  return draggableProps;
};

BottomSheet.Header = Header;
BottomSheet.Body = Body;
BottomSheet.Footer = Footer;
